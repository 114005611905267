<template>
  <div>
    <a-carousel autoplay>
      <div class="banner">
        <img src="/images/banners/banner-initial.gif" alt="" />
      </div>
    </a-carousel>
  </div>
</template>
 
<script>
export default {};
</script>
<style scoped lang="scss">
.ant-carousel>>>.slick-slide {
  text-align: center;
  height: calc(14rem + 12px);
  line-height: 160px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
}

.banner {
  text-align: center;
  height: calc(14rem + 12px);
  line-height: 160px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;

  img {
    backdrop-filter: cover;
    height: 100%;
    width: 100%;
  }
}
</style>
