<template>
  <div class="cardTopContent">
    <div class="cardImg">
      <img :src="banner" alt="Imagem" />
    </div>
    <div class="card-content">
      <div>
        <div class="title">{{ truncatedName }}</div>
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    banner: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxCharacters: 100,
    };
  },
  computed: {
    truncatedName() {
      const maxLength = 30;
      if (this.name.length <= maxLength) {
        return this.name;
      } else {
        return this.name.substring(0, maxLength) + '...';
      }
    },
    truncatedDescription() {
      if (this.description.length <= this.maxCharacters) {
        return this.description;
      } else {
        return this.description.slice(0, this.maxCharacters) + "...";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cardTopContent {
  height: 12rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 12px;
  border-radius: 10px;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  .cardImg {
    height: 100%;
    width: 100%;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-content {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow: hidden;

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.553px;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>
  