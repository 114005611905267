<template>
  <div class="cardBadge">
    <div class="content">
      <div class="card-content">
        <!-- INFORMAÇÕES -->
        <div class="info">
          <p>Selo Atual</p>
          <div class="headerTitle gradient" style="margin-bottom: 8px">{{ name }}</div>
          <p>
            {{ info }}
          </p>
        </div>
      </div>

      <!-- <div class="cardImg"><img :src="badge" alt="" /></div> -->
      <div class="cardImg">
        <img src="/images/badges/aprendiz.png" alt="" />
        <!-- assets/Plataforma/aprendiz (2).png -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    badge: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.cardBadge {
  height: calc(14rem + 12px);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

  .content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .card-content {
    height: 100%;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info {
      height: 100%;

      p {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  .cardImg {
    height: 100%;
    width: 50%;
    border-radius: 6px;
    background-image: linear-gradient(45deg, #6648F6, #FE2EE4);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 170px;
      width: 170px;
    }
  }
}
</style>
