<template>
  <div class="card">
    <div class="header">
      <div class="text" style="margin-top: 2px;">Indique e Ganhe +</div>
      <div class="img">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF" viewBox="0 0 576 512"><path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z"></path></svg>
      </div>
    </div>

    <div class="indicate">
      <div class="icon">
        <div class="svg" :class="{ color: value !== 0, default: value === 0 }">
          <svg width="22" height="22" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M14.108 2.44a1.2 1.2 0 0 1 .766.624c.58 1.16 1.013 1.864 1.448 2.436.442.578.908 1.05 1.618 1.76a8.382 8.382 0 0 1 2.46 5.94 8.402 8.402 0 0 1-16.8 0c0-2.148.82-4.3 2.46-5.94a1.2 1.2 0 0 1 2.048.848c0 1.344.084 2.368.478 3.185.216.449.553.888 1.134 1.28a37.76 37.76 0 0 1 .737-4.295c.27-1.16.606-2.316 1.007-3.281.2-.484.427-.942.684-1.34.25-.386.57-.778.986-1.055a1.2 1.2 0 0 1 .974-.161Zm.437 15.705A3.598 3.598 0 0 1 8.4 15.6s1.055.6 3 .6c0-1.2.6-4.8 1.5-5.4.6 1.2.943 1.552 1.645 2.255A3.585 3.585 0 0 1 15.6 15.6c0 .922-.352 1.843-1.055 2.545Z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
      </div>
      <div class="text">
        <div class="headerTitle gradient">Indicações</div>
      </div>
      <h4 id="valueIndicate">{{ value }}</h4>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return { valueIndicate: this.value };
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 14rem;

  .header {
    height: 30%;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 1rem;
    margin-bottom: 12px;

    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

    .text {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 108.682%;
      letter-spacing: -0.443px;
    }
  }

  .indicate {
    height: 70%;
    border-radius: 12px;

    padding: 1rem;

    text-align: center;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

    .icon {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .svg {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 3rem;
        width: 3rem;
        border-radius: 8px;
      }
    }

    .headerTitle {
      font-size: 14px;
      margin: 0;
      padding-top: 13px;
    }
  }
}

@media (max-width: 600px) {
  .card {
    height: 11rem;

    .header .text {
      font-size: 16px;
      margin: 0;
      margin-top: -5px;
    }

    .indicate {
      h4 {
        font-size: 20px;
      }

      .icon {
        .svg {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .text {
      .headerTitle {
        margin-top: -6px;
      }
    }
  }
}
</style>

