<template>
  <div class="cardContent">
    <div class="content">
      <div class="icon">
        <div class="svg">
          <svg
            width="25"
            height="25"
            fill="none"
            stroke="#ffffff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 8v13H3V8"></path>
            <path d="M1 3h22v5H1z"></path>
            <path d="M10 12h4"></path>
          </svg>
        </div>
      </div>
      <div class="text">
        <div class="headerTitle gradient">Conteúdos</div>
        <p>Adquiridos</p>
      </div>

      <h4>{{ value }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.cardContent {
  height: calc(14rem + 12px);
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

  .content {
    height: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    margin: 0;
  }

  .icon {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 4rem;
      width: 4rem;
      border-radius: 8px;
    }
  }

  .text {
    color: #fff;
    .headerTitle {
      margin: 0;
      margin-top: 12px;
      font-size: 16px;
    }
  }
}

@media (max-width: 600px) {
  .cardContent {
    height: calc(11rem + 12px);
    .icon {
      .svg {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 3rem;
        width: 3rem;
        border-radius: 8px;
        margin-top: 20px;
      }
    }
  }
}
</style>
