<template>
  <div>
    <!-- STORIES -->
    <div class="stories">
      <div class="content">
        <div class="slider">
          <!-- <a-card class="crm-bar-line header-solid h-full" style="border-radius: 50px; margin-right: 15px;"
            :bodyStyle="{ padding: '15px 0 15px 0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '75px' }">
            <a href="" class="text-center text-muted font-bold">
              <h3 class="font-semibold text-muted mb-0" style="color:aqua">+</h3>
            </a>
          </a-card> -->
          <div class="story" v-for="(data, index) in stories" :key="index" @click="openStoryModal(data)">
            <WidgetStories :contentStorie="data.thumbnail" :producer="data.producer"></WidgetStories>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL STORIES -->
    <modal name="story-modal" height="100%" :adaptive="true">
      <!-- CONTEUDO DO MODAL -->
      <div class="close-button" @click="closeStoryModal"
        style="background-color: #000; border-radius: 50px; height: 1.5em;">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" stroke="#ffffff" height="1.5em" viewBox="0 0 512 512">
          <path
            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
        </svg>
      </div>

      <div v-if="selectedStory">
        <div v-html="selectedStory.content"></div>
      </div>
    </modal>

    <!-- DASHBOARD PRODUTOR -->
    <a-row v-if="isProductor" :gutter="24" type="flex" align="stretch">
      <a-col v-for="(data, index) in producerInfoDash" :key="`data_${index}`" :span="24" :xl="12" :xs="12" class="mb-24"
        style="position: relative; z-index: 1">
        <WidgetLinkedMembers v-if="data.linkedMembers" :value="data.linkedMembers.value"></WidgetLinkedMembers>
        <WidgetNewMembers v-if="data.newMembers" :value="data.newMembers.value"></WidgetNewMembers>
        <WidgetMyProducts
          v-if="data.myProducts"
          :value="data.myProducts.value"
        ></WidgetMyProducts>
        <WidgetRevenueGenerated
          v-if="data.revenueGenerated"
          :value="data.revenueGenerated.value"
        ></WidgetRevenueGenerated>
      </a-col>
    </a-row>

    <a-row v-if="isProductor" :gutter="24" type="flex" align="stretch">
          <!-- CLIQUES -->
          <a-col
            :span="24"
            :lg="24"
            :xl="12"
            class="mb-24"
            v-for="(item, index) in click"
            :key="`click_${index}`"
            style="position: relative; z-index: 1"
          >
            <WidgetCounter
              :title="item.title"
              :value="item.value"
              :prefix="item.prefix"
              :suffix="item.suffix"
              :icon="item.icon"
              :status="item.status"
            ></WidgetCounter>
          </a-col>

          <!-- CONVERSÕES -->
          <a-col
            :span="24"
            :lg="24"
            :xl="12"
            class="mb-24"
            v-for="(item, index) in conversion"
            :key="`conversion_${index}`"
            style="position: relative; z-index: 1"
          >
            <WidgetCounter
              :title="item.title"
              :value="item.value"
              :prefix="item.prefix"
              :suffix="item.suffix"
              :icon="item.icon"
              :status="item.status"
            ></WidgetCounter>
          </a-col>
        </a-row>

        <a-row v-if="isProductor">
          <!-- Bar chart horizontal -->
          <a-col :span="24" :lg="24" class="mb-24">
            <a-card :bordered="false" class="header-solid" :bodyStyle="{padding: '0 12px 8px 3px'}">
              <template #title>
                <h6>Idade dos compradores</h6>			
              </template>
              <ChartBarHorizontal></ChartBarHorizontal>
            </a-card>
          </a-col>
        </a-row>

    <a-row v-if="!isProductor" style="margin-top: 25px;">
      <!-- CASHBACK -->
      <a-card align="right">
        <div class="cashback">
          <p>Cashback:</p>
          <h5><strong>R$</strong>1000,00</h5>
        </div>
      </a-card>
    </a-row>

    <!-- INFORMAÇÕES GERAIS -->
    <a-row v-if="!isProductor" :gutter="24" type="flex" align="stretch" style="margin-top: 25px;">
      <!-- CARD BANNER -->
      <a-col :span="24" :xl="8" :lg="12">
        <WidgetBanner class="mb-24 h-auto"></WidgetBanner>
      </a-col>

      <!-- CARD BADGE -->
      <a-col :span="24" :xl="8" :lg="12" :md="12" v-for="(badgeInfo, index) in badgeInfo" :key="`badgeInfo_${index}`">
        <CardBadge :name="badgeInfo.name" :info="badgeInfo.info" :badge="badgeInfo.badge" class="mb-24 h-auto">
        </CardBadge>
      </a-col>

      <!-- CARD CONTEUDOS -->
      <a-col :span="6" :xl="4" :lg="12" :md="6" :xs="12" v-for="(content, index) in countContent"
        :key="`content_${index}`">
        <WidgetCounterContent class="mb-24 h-auto" :value="totalContents"></WidgetCounterContent>
      </a-col>

      <!-- CARD INDICAÇÕES -->
      <a-col :span="6" :xl="4" :lg="12" :md="6" :xs="12" class="mb-24 h-auto" v-for="(indicate, index) in countIndicate"
        :key="`indicate_${index}`">
        <router-link to="/indications">
          <WidgetCounterIndicate :value="totalIndications"></WidgetCounterIndicate>
        </router-link>
      </a-col>
    </a-row>

    <!-- TOP CONTEUDOS -->
    <template v-if="!isProductor">
      <div class="headerTitle gradient">Top Conteudos</div>
    </template>

    <a-row :gutter="24" type="flex" align="stretch">
      <!-- skeletun -->
      <a-col v-if="isLoadindContents" :span="24" :xl="8" :lg="12" class="mb-24">
        <div class="skeleton-top">
          <div class="skeleton-loading-top"></div>
        </div>
      </a-col>
      <!-- skeletun -->
      <a-col v-if="isLoadindContents" :span="24" :xl="8" :lg="12" class="mb-24">
        <div class="skeleton-top">
          <div class="skeleton-loading-top"></div>
        </div>
      </a-col>
      <!-- skeletun -->
      <a-col v-if="isLoadindContents" :span="24" :xl="8" :lg="12" class="mb-24">
        <div class="skeleton-top">
          <div class="skeleton-loading-top"></div>
        </div>
      </a-col>
    </a-row>


    <a-row :gutter="24" type="flex" align="stretch">

      <a-col :span="24" :xl="8" :lg="12" class="mb-24" v-for="(content, index) in topContent" :key="index">
        <router-link :to="`/pages/offer/members/listDetails/${content.id}/${content.type}`">
          <CardTopContent :name="content.name" :description="content.description" :banner="content.banner" class="h-auto"
            id="CardTopContent"></CardTopContent>
        </router-link>
      </a-col>
    </a-row>

    <!-- PRODUTORES FÓRUM NEGOCIO -->
    <template v-if="!isProductor">
      <div class="headerTitle gradient">Fórum Negocios - Mantendo o Foco</div>
    </template>
    <div v-if="!isProductor" class="row-producers">
      <div v-for="(content, index) in producers" :key="index">
        <router-link :to="`/pages/offer/members/ListTrailProducer/${content.name}`">
          <widgetProducers :img="content.img" :name="content.name">
          </widgetProducers>
        </router-link>
      </div>
    </div>

    <!-- METAS -->
    <a-card v-if="!isProductor" :bordered="false" class="header-solid h-full mb-24">
      <template #title>
        <div class="headerTitle gradient" style="margin-bottom: 5px">Minhas meta</div>
        <p>Salve seus produtos como favoritos para incluí-los nas suas metas.</p>
      </template>
      <a-row :gutter="[24, 24]">
        <a-col :span="24" :md="12" :xl="6" v-for="(info, index) in goals" :key="index">
          <CardGoal :id="info.id" :name="info.name || info.title" :description="info.description" :img="info.banner"
            :category="info.type" :team="info.team">
          </CardGoal>
        </a-col>

        <!-- ADICIONAR NOVA META -->
        <a-col :span="24" :md="12" :xl="6" v-for="i in emptySpace()">
          <router-link :to="`/pages/Offer/members/list`">
            <div class="newGoal">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 25 25" fill="none">
                  <path
                    d="M12.1875 0C5.4675 0 0 5.4675 0 12.1875C0 18.9075 5.4675 24.375 12.1875 24.375C18.9075 24.375 24.375 18.9075 24.375 12.1875C24.375 5.4675 18.9075 0 12.1875 0ZM16.875 13.125H13.125V16.875C13.125 17.1236 13.0262 17.3621 12.8504 17.5379C12.6746 17.7137 12.4361 17.8125 12.1875 17.8125C11.9389 17.8125 11.7004 17.7137 11.5246 17.5379C11.3488 17.3621 11.25 17.1236 11.25 16.875V13.125H7.5C7.25136 13.125 7.0129 13.0262 6.83709 12.8504C6.66127 12.6746 6.5625 12.4361 6.5625 12.1875C6.5625 11.9389 6.66127 11.7004 6.83709 11.5246C7.0129 11.3488 7.25136 11.25 7.5 11.25H11.25V7.5C11.25 7.25136 11.3488 7.0129 11.5246 6.83709C11.7004 6.66127 11.9389 6.5625 12.1875 6.5625C12.4361 6.5625 12.6746 6.66127 12.8504 6.83709C13.0262 7.0129 13.125 7.25136 13.125 7.5V11.25H16.875C17.1236 11.25 17.3621 11.3488 17.5379 11.5246C17.7137 11.7004 17.8125 11.9389 17.8125 12.1875C17.8125 12.4361 17.7137 12.6746 17.5379 12.8504C17.3621 13.0262 17.1236 13.125 16.875 13.125Z"
                    fill="url(#paint0_linear_1044_1767)" />
                  <defs>
                    <linearGradient id="paint0_linear_1044_1767" x1="3.80242e-07" y1="24.375" x2="24.2392" y2="-0.13429"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6648F6" />
                      <stop offset="1" stop-color="#FE2EE4" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div class="text">
                <h6>Adicionar Nova Meta +</h6>
                <p>Favorite um conteudo para <br> adiciona-lo as suas metas!</p>
              </div>
            </div>
          </router-link>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>


<script>
import Vue from "vue";
import VModal from "vue-js-modal";
Vue.use(VModal);

// Data for stories and producer information
const stories = [
  // Story 1
  {
    id: "0",
    producer: "ALIGA",
    thumbnail: "https://res.cloudinary.com/consigmais/image/upload/v1701458787/ijzeus2dk2kzrevzvnkb.png",
    content:
      '<iframe width="353" height="627" src="https://www.youtube.com/embed/cPsT-u8VKlE" title="ALIGA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  // Story 1
  {
    id: "1",
    producer: "Tiago",
    thumbnail: "https://img.youtube.com/vi/KZuDYXNKr6A/default.jpg",
    content:
      '<iframe width="353" height="627" src="https://www.youtube.com/embed/KZuDYXNKr6A" title="Pare de andar com idi0t4s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  // Story 2
  {
    id: "2",
    producer: "Tiago",
    thumbnail: "https://img.youtube.com/vi/pJSXMSpmRvI/default.jpg",
    content:
      '<iframe width="353" height="627" src="https://www.youtube.com/embed/pJSXMSpmRvI" title="Vai trabalhar ou ficar chorando?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  // Story 3
  {
    id: "3",
    producer: "Tiago",
    thumbnail: "https://img.youtube.com/vi/4724TKwAOGc/default.jpg",
    content:
      '<iframe width="353" height="627" src="https://www.youtube.com/embed/4724TKwAOGc" title="Pare de andar com idi0t4s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
];

const producers = [
  {
    img: "/images/producers/producers-thiago-reis.png",
    name: "Thiago Reis",
  },
  {
    img: "/images/producers/producers-alfredo.png",
    name: "Alfredo Soares",
  },
  {
    img: "/images/producers/producers-thiago-eugenio.png",
    name: "Thiago Eugenio",
  },
  {
    img: "/images/producers/producers-giovani-begossi.png",
    name: "Giovani Begossi",
  },
  {
    img: "/images/producers/producers-natalia-beauty.png",
    name: "Natalia Beauty",
  },
  {
    img: "/images/producers/producers-joel-jota.png",
    name: "Joel Jota",
  },
];

/* CLIQUE */
const click = [
  {
    title: "Cliques",
    value: 0,
    prefix: "",
    suffix: "+0%",
    icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`,
  },
];

/* CONVERSÇÕES */
const conversion = [
  {
    title: "Conversões",
    value: 0,
    prefix: "R$",
    suffix: "+0%",
    icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z" fill="#111827"/>
						</svg>`,
  },
];

const producerInfoDash = [
  { linkedMembers: { value: 150 } },
  { newMembers: { value: 12 } },
  { myProducts: { value: 0 } },
  { revenueGenerated: { value: 0 } },
];

// Placeholder arrays
const goals = [];
const countContent = [{ value: 0 }];
const countIndicate = [{ value: 0 }];
const badgeInfo = [
  {
    name: "Aprendiz",
    info: "Parabéns! Toda grande história sempre tem um começo e você está no lugar certo.",
    badge: "",
  },
];

// Importing Vue components
import CardBadge from "../../components/Cards/CardBadge";
import CardTopContent from "../../components/Cards/CardTopContent";
import CardGoal from "../../components/Cards/CardGoal";
import ChartBarHorizontal from '../../components/Charts/ChartBarHorizontal' ;
import WidgetCounter from "../../components/Widgets/WidgetCounter";
import WidgetCounterContent from "../../components/Widgets/WidgetCounterContent";
import WidgetCounterIndicate from "../../components/Widgets/WidgetCounterIndicate";
import WidgetBanner from "../../components/Widgets/WidgetBanner";
import WidgetStories from "../../components/Widgets/WidgetStories";
import WidgetProducers from "../../components/Widgets/WidgetProducers";
import WidgetLinkedMembers from "../../components/Widgets/producer/WidgetLinkedMembers";
import WidgetNewMembers from "../../components/Widgets/producer/WidgetNewMembers";
import WidgetMyProducts from "../../components/Widgets/producer/WidgetMyProducts";
import WidgetRevenueGenerated from "../../components/Widgets/producer/WidgetRevenueGenerated";

export default {
  components: {
    // Member components
    WidgetStories,
    WidgetBanner,
    CardBadge,
    ChartBarHorizontal,
    WidgetCounter,
    WidgetCounterContent,
    WidgetCounterIndicate,
    CardTopContent,
    CardGoal,
    WidgetProducers,
    // Producer components
    WidgetLinkedMembers,
    WidgetNewMembers,
    WidgetMyProducts,
    WidgetRevenueGenerated,
  },
  data() {
    return {
      // Data properties
      isLoadindContents: true,
      isProductor: localStorage.getItem("userType") === "producer",
      countContent,
      countIndicate,
      allContent: [],
      favoriteOffers: [],
      topContent: [],
      goals: [],
      stories,
      selectedStory: null,
      selectedContent: null,
      heightValue: "80%",
      widthValue: "80%",
      modal: "",
      producerInfoDash,
      badgeInfo,
      producers,
      totalIndications: 0,
      totalContents: 0,
      click,
      conversion,
    };
  },
  mounted() {
    // Initializations on component mount
    this.getAllContent();
    this.countIndications();
    this.getGoals();

    this.favoriteOffers = JSON.parse(localStorage.getItem('favoriteOffers')) || [];

    const screenWidth = window.innerWidth || document.documentElement.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight;

    // Atualiza as dimensões em todos os objetos do array
    this.stories.forEach((story) => {
      story.content = story.content.replace('width="353"', `width="${screenWidth}"`);
      story.content = story.content.replace('height="627"', `height="${screenHeight}"`);
    });
  },
  methods: {
    closeStoryModal() {
      this.$modal.hide("story-modal");
      this.selectedStory = null;
    },
    countIndications() {
      this.$api.dataService
        .getData("indication/all")
        .then((res) => {
          this.totalIndications = res.length;
        })
        .catch((error) => {
          console.error("Erro ao buscar ofertas:", error);
        });
    },
    getAllContent() {
      this.$api.dataService
        .getData("member/purchasable")
        .then((res) => {

          this.totalContents = res.events.length + res.products.length + res.courses.length;

          if (res.events || res.products || res.courses) {
            this.allContent = [
              ...this.mapOffers(res.events, "Event"),
              ...this.mapOffers(res.products, "Product"),
              ...this.mapOffers(res.courses, "Course"),
            ];
            this.identifyTopProducts();
            this.isLoadindContents = false;
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar ofertas:", error);
        });
    },
    mapOffers(offers, type) {
      if (!offers) return [];
      return offers.map((offer) => ({
        id: offer.id,
        name: offer.name || offer.title,
        description: offer.description,
        banner: offer.banner
          ? offer.banner["1x1"]
          : "https://lp-js-libs.s3.sa-east-1.amazonaws.com/assets/Plataforma/sem-imagem.jpg",
        type: type,
      }));
    },
    identifyTopProducts() {
      if (this.allContent.length > 0) {
        this.topContent = this.allContent.slice(0, 3);
      }
    },
    getGoals() {
      this.$api.dataService
        .getData("/bookmarks")
        .then((res) => {
          const bookmarksData = typeof res === 'string' ? JSON.parse(res) : res;

          const courseBookmarks = (bookmarksData.courseBookmarks || []).map(bookmark => ({ ...bookmark, type: 'Course' }));
          const eventBookmarks = (bookmarksData.eventBookmarks || []).map(bookmark => ({ ...bookmark, type: 'Event' }));
          const productBookmarks = (bookmarksData.productBookmarks || []).map(bookmark => ({ ...bookmark, type: 'Product' }));

          this.goals = [...courseBookmarks, ...eventBookmarks, ...productBookmarks];
        })
        .catch((error) => {
          console.error("Erro ao buscar ofertas:", error);
        });
    },

    emptySpace() {
      const maxSpaces = 4;
      return Math.max(maxSpaces - this.goals.length, 0);
    },
    openStoryModal(storie) {
      this.selectedStory = storie;
      this.$modal.show("story-modal");
    },
  },
  computed: {
    date() {
      // Placeholder for computed property
    },
  },
};
</script>



<style lang="scss" scoped>
/* STORIES */
.stories {
  margin-top: 10px;
  margin-bottom: -5px;
  width: 100%;
  padding-bottom: 12px;
}

.content {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  cursor: grab;
}

.content::-webkit-scrollbar {
  overflow-x: auto;
  white-space: nowrap;
}

.content::-webkit-scrollbar {
  width: 6px;
}

.content::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 3px;
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
}

.slider {
  display: flex;
}

.story {
  flex: 0 0 auto;
  margin-right: 20px;
  cursor: pointer;
}

@media (max-width: 992px) {
  #CardTopContent {
    margin-bottom: 5px;
  }
}

/* CASHBACK */
.cashback {
  p {
    margin: 0;
  }

  h5 {
    margin: 0;
  }
}


/* METAS */
.newGoal {
  height: 315px;
  width: 100%;
  background: #0d0f14;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 2px #fff;
  border-style: dashed;
  border-radius: 12px;

  .text {
    h1 {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.553px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.553px;
    }
  }
}

/* Estilos para o botão de fechar */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
}

/* ROW PRODUCERS */
.row-producers {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  overflow: auto;
}

.skeleton-loading-top {
  width: 100%;
  height: 200px;
  /* Ajuste a altura conforme necessário */
  background: linear-gradient(90deg, #0e1117 25%, #10161d 50%, #1a232e 75%);
  background-size: 200% 100%;
  animation: loading 3.5s infinite;
  border-radius: 10px;
  margin-bottom: 24px;
}
</style>

