<template>
  <!-- Counter widget -->
  <a-card :bordered="false" class="widget-1">
    <a-statistic
      title="Receita Total"
      :value="value"
      prefix="R$ "
      :precision="0"
      class="text-success"
      :class="'text-' + status"
    >
    </a-statistic>
    <div class="icon">
      <svg
        width="22"
        height="22"
        fill="none"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.25 15.75h19.5a.75.75 0 0 0 .75-.75V4.5a.75.75 0 0 0-.75-.75H2.25a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75Z"
        ></path>
        <path d="M3 18h18"></path>
        <path d="M4.5 20.25h15"></path>
        <path d="M12 13.5A3.75 3.75 0 1 0 12 6a3.75 3.75 0 0 0 0 7.5Z"></path>
        <path d="M22.5 7.5a3.75 3.75 0 0 1-3.75-3.75"></path>
        <path d="M1.5 7.5a3.75 3.75 0 0 0 3.75-3.75"></path>
        <path d="M22.5 12a3.75 3.75 0 0 0-3.75 3.75"></path>
        <path d="M1.5 12a3.75 3.75 0 0 1 3.75 3.75"></path>
      </svg>
    </div>
  </a-card>
  <!-- / Counter widget -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {};
  },
};
</script>