<template>
  <!-- Counter widget -->
  <a-card :bordered="false" class="widget-1">
    <a-statistic
      title="Produtos"
      :value="value"
      :precision="0"
      class="text-success"
      :class="'text-' + status"
    >
    </a-statistic>
    <div class="icon">
      <svg
        width="22"
        height="22"
        fill="none"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22 7 12 2 2 7v10l10 5 10-5V7Z"></path>
        <path d="m2 7 10 5"></path>
        <path d="M12 22V12"></path>
        <path d="m22 7-10 5"></path>
        <path d="m17 4.5-10 5"></path>
      </svg>
    </div>
  </a-card>
  <!-- / Counter widget -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {};
  },
};
</script>