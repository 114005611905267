<template>
  <div>
    <div class="storie">
      <div style="background-color: #0d0f15; border-radius: 50px; padding: 3px;">
      <img :src="contentStorie" alt="" />
     </div>
    </div>
    <div style="text-align: center;">
      <span style="margin: auto; color: #b5b5b5; font-size: small;">{{ producer }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentStorie: {
      type: String,
      default: "",
    },
    producer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.stories {
  margin-bottom: 20px;
  cursor: pointer;
  img {
    height: 75px;
    width: 75px;
    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    object-fit: cover; /* Adicionada esta propriedade para estilo cover */

  }
}
</style>