<template>
  <a-card class="card-project">
    <img slot="cover" alt="example" :src="img['1x1']" />


    <!-- <P class="card-tag">{{ category }}</P> -->
    <div class="headerTitle">{{ truncatedName }}</div>
    <p>
      {{ truncatedDescription }}
    </p>
    <a-row type="flex" :gutter="6" class="card-footer" align="middle">
      <a-col :span="12">
        <router-link :to="`/pages/offer/members/listDetails/${id}/${category}`">
          <a-button class="buttonContent" size="small">VER CONTEÚDO</a-button>
        </router-link>
      </a-col>
      <!-- <a-col :span="12" class="text-right">
        <a-space :size="-12" class="avatar-chips">
          <a-avatar size="small" v-for="(img, index) in team" :key="index" :src="img" />
        </a-space>
      </a-col> -->
    </a-row>
  </a-card>
</template>

<style lang="scss">
.card-project {
  img {
    height: 100%;
  }

  .headerTitle {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .buttonContent {
    border-radius: 6px;
    border: 1px solid #6648F6;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
      0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    img: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    team: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    truncatedName() {
      const maxLength = 30;
      if (this.name.length <= maxLength) {
        return this.name;
      } else {
        return this.name.substring(0, maxLength) + '...';
      }
    },
    truncatedDescription() {
      const maxLength = 250;
      if (this.description.length <= maxLength) {
        return this.description;
      } else {
        return this.description.substring(0, maxLength) + '...';
      }
    }
  },
  mounted() {
  },
};
</script>
