<template>
    <div>
        <div class="producers">
            <div class="img">
                <img :src="img" alt="" />
            </div>
            <h6>{{ name }}</h6>
        </div>
    </div>
</template>
    
<script>
export default {
    props: {
        img: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
};
</script>
  
<style lang="scss">
.producers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    .img {
        img {
            height: 150px;
            width: 150px;
            border-radius: 500px;
        }
    }

    @media (max-width: 1251px) {
        .img {
            img {
                height: 120px;
                width: 120px;

                padding: 3px;
            }
        }
    }
}
</style>